import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Grid, Typography, Card, CardActions, CardContent, LinearProgress, List } from '@material-ui/core'
import { useLocation, useNavigate } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'
import { ArrowDown } from 'react-feather'
import { Form } from '@unform/web'

import { useTranslation } from 'react-i18next'

import OpenNavigation from '../../components/OpenNavigation'
import Input from '../../components/Input'
import { currency } from '../../services/atom'

import api from '../../services/api'

import useStyles from './style'

const Products = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const location = useLocation()

  const formRef = useRef(null)
  const modelRef = useRef(null)
  const yearRef = useRef(null)
  const motorRef = useRef(null)

  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])

  const { t } = useTranslation()

  const [empty, setEmpty] = useState(false)

  const [manufacturer, setManufacturer] = useState()
  const [manufactures, setManufactures] = useState([])

  const [motor, setMotor] = useState()
  const [motors, setMotors] = useState([])

  const [year, setYear] = useState()
  const [years, setYears] = useState([])

  const [model, setModel] = useState()
  const [models, setModels] = useState([])

  const openProduct = useCallback((product) => {
    navigate(`/product/${product._id}`)
  }, [])

  const fetchManufactures = useCallback(async () => {
    setLoading(true)
    const response = await api.get('manufactures')
    setManufactures(response.data)
    setLoading(false)
  }, [])

  const fetchModels = useCallback(async () => {
    if (manufacturer?._id) {
      setLoading(true)
      const response = await api.get(`models/manufacturer/${manufacturer?._id}`)
      setModels(response.data)
      setLoading(false)
    }
  }, [manufacturer])

  const fetchYears = useCallback(async () => {
    if (model?._id) {
      setLoading(true)
      const response = await api.get(`years/model/${model?._id}`)
      setYears(response.data)
      setLoading(false)
    }
  }, [model])

  const fetchMotor = useCallback(async () => {
    if (year?._id) {
      setLoading(true)
      const response = await api.get(`motors/year/${year?._id}`)
      setMotors(response.data)
      setLoading(false)
    }
  }, [year])

  const fetchProducts = useCallback(async () => {
    if (motor?._id) {
      setLoading(true)
      const response = await api.get(`stage/motor/${motor?._id}`)
      setProducts(response.data)
      if (motor?.length >= 0) {
        setEmpty(true)
      }
      setLoading(false)
    }
  }, [motor])

  useEffect(async () => {
    await fetchYears()
  }, [model])

  useEffect(async () => {
    await fetchMotor()
  }, [year])

  useEffect(async () => {
    await fetchModels()
  }, [manufacturer])

  useEffect(async () => {
    await fetchProducts()
  }, [motor])

  useEffect(async () => {
    await fetchManufactures()
  }, [])

  return (
    <Grid container component='main' className={classes.root}>
      <OpenNavigation />
      <Grid item xs={12} sm={12} md={12} style={{ background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/1.jpg')", justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundPosition: 'center', backgroundSize: 'cover', overflow: 'hidden', height: '45%' }}>
        <Typography variant='h2' color='primary' style={{ marginTop: 100, alignContent: 'center', textAlign: 'center', justifyContent: 'center', fontWeight: 'normal' }}>
          {t('product')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.body}>
        <Form ref={formRef} className={classes.form} initialData={location.state?.stage}>
          <Grid container className={classes.search}>
            <Grid item xs={12} sm={3} md={3} className={classes.prod}>
              <Autocomplete
                fullWidth
                noOptionsText='Nenhuma opção'
                variant='outlined'
                margin='normal'
                id='manufacturer'
                name='manufacturer'
                className={classes.select}
                options={manufactures}
                value={manufacturer}
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <>
                    <span style={{
                      backgroundImage: `url(/logos/${option.name.replace(' ', '-').toLowerCase()}.png)`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      width: 30,
                      height: 30,
                      marginRight: 10
                    }}
                    />
                    {option.name}
                  </>
                )}
                renderInput={(params) => <Input {...params} id='manufacturer' autoFocus name='manufacturer' label={t('select.manufacturer')} variant='outlined' />}
                onChange={(_, newValue) => {
                  setManufacturer(newValue)
                  setModel()
                  setYear()
                  setMotor()
                  modelRef.current.focus()
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} className={classes.prod}>
              <Autocomplete
                noOptionsText='Nenhuma opção'
                disabled={models.length === 0}
                variant='outlined'
                margin='normal'
                fullWidth
                id='model'
                className={classes.select}
                options={models}
                value={model}
                ref={modelRef}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => <Input {...params} id='model' name='model' label={t('select.model')} variant='outlined' />}
                onChange={(_, newValue) => {
                  setModel(newValue)
                  setYear()
                  setMotor()
                  yearRef.current.focus()
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3} className={classes.prod}>
              <Autocomplete
                noOptionsText='Nenhuma opção'
                disabled={years.length === 0}
                variant='outlined'
                margin='normal'
                fullWidth
                id='year'
                className={classes.select}
                options={years}
                value={year}
                getOptionLabel={(option) => `${option.year}`}
                ref={yearRef}
                renderInput={(params) => <Input {...params} id='year' name='year' label={t('select.year')} variant='outlined' />}
                onChange={(_, newValue) => {
                  setYear(newValue)
                  setMotor()
                  motorRef.current.focus()
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3} className={classes.prod}>
              <Autocomplete
                noOptionsText='Nenhuma opção'
                disabled={motors.length === 0}
                variant='outlined'
                margin='normal'
                fullWidth
                id='motor'
                className={classes.select}
                options={motors}
                value={motor}
                getOptionLabel={(option) => `${option.name}`}
                ref={motorRef}
                renderInput={(params) => <Input {...params} id='motor' name='motor' label={t('select.engine')} variant='outlined' />}
                onChange={(_, newValue) => { setMotor(newValue) }}
              />
            </Grid>
          </Grid>
          {loading && <LinearProgress color='primary' />}

          {products.length > 0
            ? (
              <>
                {products.map((product, index) =>
                  <Card key={index} style={{ padding: 20, marginBottom: 20, cursor: 'pointer' }} variant='outlined' onClick={() => openProduct(product)}>
                    <CardContent>
                      <Typography className={classes.title} color='primary' gutterBottom>
                        {product.name}
                      </Typography>
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                          <List style={{ textAlign: 'center' }}>
                            <Typography variant='h4'>POWER</Typography>
                            <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                              ORIGINAL
                            </Typography>
                            <Typography variant='h6' style={{ fontWeight: 'bold', fontSize: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                              {`${product.motor?.originalPower}`}
                              <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                                HP
                              </Typography>
                            </Typography>
                            <ArrowDown size={35} color='#f15b2b' />
                            <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                              MODIFICADO
                            </Typography>
                            <Typography variant='h6' color='primary' style={{ fontWeight: 'bold', fontSize: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                              {`${product.newPower}`}
                              <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                                HP
                              </Typography>
                            </Typography>
                          </List>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <List style={{ textAlign: 'center' }}>
                            <Typography variant='h5'>TORQUE</Typography>
                            <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                              ORIGINAL
                            </Typography>

                            <Typography variant='h6' style={{ fontWeight: 'bold', fontSize: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                              {`${product.motor?.originalTorque}`}
                              <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                                NM
                              </Typography>
                            </Typography>
                            <ArrowDown size={35} color='#f15b2b' />
                            <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                              MODIFICADO
                            </Typography>
                            <Typography variant='h6' color='primary' style={{ fontWeight: 'bold', fontSize: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                              {`${product.newTorque}`}
                              <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                                NM
                              </Typography>
                            </Typography>
                          </List>
                        </Grid>
                      </Grid>
                      <Typography className={classes.pos} color='textSecondary'>
                        {product.description}
                      </Typography>
                    </CardContent>
                    <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography variant='h6' color='textSecondary' style={{ fontWeight: 'bold', fontSize: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {product.price ? currency(product.price) : 'Consulte valor'}
                      </Typography>
                    </CardActions>
                  </Card>)}
              </>)
            : (
              <Grid container>
                <Grid item xs={12} sm={12} md={12} className={classes.title}>
                  <Typography variant='h5' style={{ fontWeight: 'bold', marginBottom: 20 }}>
                    {empty ? t('contact.product') : t('select.product')}
                  </Typography>
                </Grid>
              </Grid>)}
        </Form>
      </Grid>
    </Grid>
  )
}

export default Products
