import React, { useState, useEffect } from 'react'
import { CardActions, Grid, Typography, Card, CardContent, List, LinearProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ArrowDown } from 'react-feather'

import OpenNavigation from '../../components/OpenNavigation'
import ProductHeader from '../../components/ProductHeader'
import { currency } from '../../services/atom'

import api from '../../services/api'

import useStyles from './style'

const SignIn = () => {
  const classes = useStyles()
  const params = useParams()

  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState([])

  const { t } = useTranslation()

  useEffect(async () => {
    const { _id } = params
    const response = await api.get(`stage/${_id}`)
    setProduct(response.data)
    setLoading(false)
  }, [])

  return (
    <Grid container component='main' className={classes.root}>
      <OpenNavigation />
      <ProductHeader _id={product._id} name={t('product')} />
      <Grid item xs={12} sm={12} md={12} className={classes.body}>
        {loading && <LinearProgress />}
        <Card style={{ padding: 20, marginBottom: 20 }} variant='outlined'>
          <CardContent>
            <Typography className={classes.title} color='primary' gutterBottom>
              {product.name}
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <List style={{ textAlign: 'center' }}>
                  <Typography variant='h4'>POWER</Typography>
                  <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                    ORIGINAL
                  </Typography>
                  <Typography variant='h6' style={{ fontWeight: 'bold', fontSize: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {`${product.motor?.originalPower}`}
                    <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                      HP
                    </Typography>
                  </Typography>
                  <ArrowDown size={35} color='#f15b2b' />
                  <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                    MODIFICADO
                  </Typography>
                  <Typography variant='h6' color='primary' style={{ fontWeight: 'bold', fontSize: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {`${product.newPower}`}
                    <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                      HP
                    </Typography>
                  </Typography>
                </List>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <List style={{ textAlign: 'center' }}>
                  <Typography variant='h5'>TORQUE</Typography>
                  <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                    ORIGINAL
                  </Typography>

                  <Typography variant='h6' style={{ fontWeight: 'bold', fontSize: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {`${product.motor?.originalTorque}`}
                    <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                      NM
                    </Typography>
                  </Typography>
                  <ArrowDown size={35} color='#f15b2b' />
                  <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                    MODIFICADO
                  </Typography>
                  <Typography variant='h6' color='primary' style={{ fontWeight: 'bold', fontSize: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {`${product.newTorque}`}
                    <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                      NM
                    </Typography>
                  </Typography>
                </List>
              </Grid>
            </Grid>
            <Typography className={classes.pos} color='textSecondary'>
              {product.description}
            </Typography>
          </CardContent>
          <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='h6' color='textSecondary' style={{ fontWeight: 'bold', fontSize: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {product.price ? currency(product.price) : 'Consulte valor'}
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}

export default SignIn
