import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: { height: '100vh' },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  plus: {
    marginLeft: theme.spacing(1)
  },
  trash: {
    marginLeft: theme.spacing(1)
  },
  thumbnail: {
    borderRadius: 10,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 300
  },
  chart: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1)
  }
}))

export default useStyles
