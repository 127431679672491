import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Button, LinearProgress, CardActions, CardContent, Card, Grid, Typography, Box, Container, Link, Divider, Drawer, List, ListItem, IconButton, AppBar, Toolbar, Hidden } from '@material-ui/core'
import { LogIn, Menu, Facebook, Instagram, Youtube, ArrowDown } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Autocomplete } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { Form } from '@unform/web'

import config from '../../config'

import LanguageSelect from '../../components/LanguageSelect'
import Input from '../../components/Input'
import useStyles from './style'

import { currency } from '../../services/atom'
import api from '../../services/api'

const Home = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const formRef = useRef(null)
  const modelRef = useRef(null)
  const yearRef = useRef(null)
  const motorRef = useRef(null)

  const navigate = useNavigate()
  const location = useLocation()

  const [configuration, setConfiguration] = useState({})
  const [loading, setLoading] = useState(true)
  const [store, setStore] = useState(false)
  const [stages, setStages] = useState([])
  const [open, setOpen] = useState(false)

  const [products, setProducts] = useState([])

  const [manufacturer, setManufacturer] = useState()
  const [manufactures, setManufactures] = useState([])

  const [motor, setMotor] = useState()
  const [motors, setMotors] = useState([])

  const [year, setYear] = useState()
  const [years, setYears] = useState([])

  const [model, setModel] = useState()
  const [models, setModels] = useState([])

  const fetchConfiguration = useCallback(async () => {
    const response = await api.get('/configuration/public')
    setConfiguration(response.data)
  }, [])

  const fetchStore = useCallback(async () => {
    const response = await api.get('/configuration/store')
    setStore(response.data)
  }, [])

  const fetchStages = useCallback(async () => {
    const response = await api.get('/stages/home')
    setStages(response.data)
  }, [])

  const openProduct = useCallback((product) => {
    navigate(`/product/${product._id}`)
  }, [])

  const fetchManufactures = useCallback(async () => {
    const response = await api.get('manufactures')
    setManufactures(response.data)
  }, [])

  const fetchModels = useCallback(async () => {
    if (!manufacturer) return
    const response = await api.get(`models/manufacturer/${manufacturer?._id}`)
    setModels(response.data)
  }, [manufacturer])

  const fetchYears = useCallback(async () => {
    if (!model) return
    const response = await api.get(`years/model/${model?._id}`)
    setYears(response.data)
  }, [model])

  const fetchMotor = useCallback(async () => {
    if (!year) return
    const response = await api.get(`motors/year/${year?._id}`)
    setMotors(response.data)
  }, [year])

  const fetchProducts = useCallback(async () => {
    if (!motor) return
    const response = await api.get(`stage/motor/${motor?._id}`)
    setProducts(response.data)
  }, [motor])

  useEffect(async () => {
    await fetchYears()
  }, [model])

  useEffect(async () => {
    await fetchMotor()
  }, [year])

  useEffect(async () => {
    await fetchModels()
  }, [manufacturer])

  useEffect(async () => {
    await fetchProducts()
  }, [motor])

  useEffect(async () => {
    await fetchManufactures()
  }, [])

  useEffect(() => {
    fetchConfiguration()
    fetchStore()
    fetchStages()
    setLoading(false)
  }, [])

  return (
    <Grid container component='main' className={classes.root}>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolBar} variant='dense'>
          <a href='/' style={{ color: 'white' }}>
            <img src='/perfortech-branco.png' className={classes.logo} />
          </a>
          <Box flexGrow={1} />
          <Hidden mdUp>
            <IconButton edge='start' onClick={() => setOpen(!open)} className={classes.menuButton} aria-label='open drawer'>
              <Menu />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            {store?.store &&
              <a style={{ textDecoration: 'none' }} href={store?.store} target='_blank' rel='noreferrer'>
                <Button size='medium' color='primary' style={{ marginRight: 10, paddingRight: 20, paddingLeft: 20 }}>
                  {t('menu.store')}
                </Button>
              </a>}
            <Button href='/about' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.sobre')}
            </Button>
            <Button href='/stage-services' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.stages')}
            </Button>
            <Button href='/contact' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.contato')}
            </Button>
            <Button href='/products' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.performance')}
            </Button>
            <Button href='/news' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.posts')}
            </Button>
            <Button href='/dealers' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.dealers')}
            </Button>
            <LanguageSelect />
            <Link href='/login' variant='body2' color='textSecondary'>
              <IconButton edge='start' className={classes.menuButton} color='inherit'>
                <LogIn />
              </IconButton>
            </Link>
          </Hidden>
        </Toolbar>
      </AppBar>

      <Drawer anchor='right' open={open} onClose={() => setOpen(!open)}>
        <List className={classes.drawerList}>
          <img src='/perfortech-branco.png' className={classes.sideLogo} />
          {store?.store &&
            <>
              <ListItem button>
                <Button href={store?.store} target='_blank' fullWidth variant='contained' color='secondary'>
                  {t('menu.store')}
                </Button>
              </ListItem>
            </>}
          <ListItem button>
            <Button href='/about' fullWidth variant='text' color='secondary'>
              {t('menu.sobre')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/stage-services' fullWidth variant='text' color='secondary'>
              {t('menu.stages')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/contact' fullWidth variant='text' color='secondary'>
              {t('menu.contato')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/products' fullWidth variant='text' color='secondary'>
              {t('menu.performance')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/news' fullWidth variant='text' color='secondary'>
              {t('menu.posts')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/dealers' fullWidth variant='text' color='secondary'>
              {t('menu.dealers')}
            </Button>
          </ListItem>
          <Divider />
          <LanguageSelect />
          <Divider />
          <Link href='/login' variant='body2' color='textSecondary'>
            <IconButton edge='start' className={classes.menuButton} color='inherit'>
              <LogIn />
            </IconButton>
          </Link>
        </List>
      </Drawer>

      <Grid item xs={12} sm={12} md={12} className={classes.titleGrid}>
        <Hidden smDown>
          <Typography variant='h1' className={classes.title}>{t('perfortech.title')}</Typography>
        </Hidden>
        <Hidden mdUp>
          <Typography variant='h2' className={classes.title}>{t('perfortech.title')}</Typography>
        </Hidden>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className={classes.select}>
        <Typography variant='h4' className={classes.selectTitle}>RECENT REMAPS</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.select2}>
        {loading
          ? <LinearProgress />
          : (
            <>
              {stages.length > 0
                ? (
                  <>
                    {stages.map((stage, index) =>
                      <div key={index} className={classes.reremapLink}>
                        <Link href={`/product/${stage._id}`} variant='body2' color='textSecondary'>
                          <div><img src={stage?.image?.name ? `${config[process.env.NODE_ENV].blob}${stage?.image?.name}` : `/images/${Math.floor(Math.random() * 3)}.jpg`} style={{ maxHeight: 200, maxWidth: 300, padding: 5 }} /></div>
                          <Typography variant='subtitle1'>{stage.manufacturer.name} - {stage.model.name}</Typography>
                          <Typography variant='h6' color='primary'>{stage.motor.name}</Typography>
                        </Link>
                      </div>
                    )}
                  </>)
                : (
                  <Link variant='body2' color='textSecondary' className={classes.reremapLink}>
                    <Typography variant='subtitle1'>{t('anyOne')}</Typography>
                  </Link>)}
            </>)}
      </Grid>

      <Grid item xs={12} sm={12} md={12} className={classes.select}>
        <Typography variant='h4' className={classes.selectTitle}>{t('perfortech.select')}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Form ref={formRef} className={classes.form} initialData={location.state?.stage}>
          <Grid container className={classes.search}>
            <Grid item xs={12} sm={3} md={3} className={classes.prod}>
              <Autocomplete
                noOptionsText='Nenhuma opção'
                variant='outlined'
                margin='normal'
                fullWidth
                id='manufacturer'
                name='manufacturer'
                className={classes.select}
                options={manufactures}
                value={manufacturer}
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(option) => (
                  <>
                    <span style={{
                      backgroundImage: `url(/logos/${option.name.replace(' ', '-').toLowerCase()}.png)`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      width: 30,
                      height: 30,
                      marginRight: 10
                    }}
                    />
                    {option.name}
                  </>
                )}
                renderInput={(params) => <Input {...params} id='manufacturer' name='manufacturer' label={t('select.manufacturer')} variant='outlined' />}
                onChange={(_, newValue) => {
                  setManufacturer(newValue)
                  setModel()
                  setYear()
                  setMotor()
                  modelRef.current.focus()
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} className={classes.prod}>
              <Autocomplete
                noOptionsText='Nenhuma opção'
                disabled={models.length === 0}
                variant='outlined'
                margin='normal'
                fullWidth
                id='model'
                className={classes.select}
                options={models}
                value={model}
                ref={modelRef}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => <Input {...params} id='model' name='model' label={t('select.model')} variant='outlined' />}
                onChange={(_, newValue) => {
                  setModel(newValue)
                  setYear()
                  setMotor()
                  yearRef.current.focus()
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3} className={classes.prod}>
              <Autocomplete
                noOptionsText='Nenhuma opção'
                disabled={years.length === 0}
                variant='outlined'
                margin='normal'
                fullWidth
                id='year'
                className={classes.select}
                options={years}
                value={year}
                ref={yearRef}
                getOptionLabel={(option) => `${option.year}`}
                renderInput={(params) => <Input {...params} id='year' name='year' label={t('select.year')} variant='outlined' />}
                onChange={(_, newValue) => { setYear(newValue) }}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3} className={classes.prod}>
              <Autocomplete
                noOptionsText='Nenhuma opção'
                disabled={motors.length === 0}
                variant='outlined'
                margin='normal'
                fullWidth
                id='motor'
                className={classes.select}
                options={motors}
                value={motor}
                ref={motorRef}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => <Input {...params} id='motor' name='motor' label={t('select.engine')} variant='outlined' />}
                onChange={(_, newValue) => { setMotor(newValue) }}
              />
            </Grid>
            {products.length > 0 && (
              <>
                {products.map((product, index) =>
                  <Card key={index} style={{ padding: 20, marginBottom: 20, cursor: 'pointer' }} variant='outlined' onClick={() => openProduct(product)}>
                    <CardContent>
                      <Typography className={classes.title} color='primary' gutterBottom>
                        {product.name}
                      </Typography>
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                          <List style={{ textAlign: 'center' }}>
                            <Typography variant='h4'>POWER</Typography>
                            <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                              ORIGINAL
                            </Typography>
                            <Typography variant='h6' style={{ fontWeight: 'bold', fontSize: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                              {`${product.motor?.originalPower}`}
                              <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                                HP
                              </Typography>
                            </Typography>
                            <ArrowDown size={35} color='#f15b2b' />
                            <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                              MODIFICADO
                            </Typography>
                            <Typography variant='h6' color='primary' style={{ fontWeight: 'bold', fontSize: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                              {`${product.newPower}`}
                              <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                                HP
                              </Typography>
                            </Typography>
                          </List>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <List style={{ textAlign: 'center' }}>
                            <Typography variant='h5'>TORQUE</Typography>
                            <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                              ORIGINAL
                            </Typography>

                            <Typography variant='h6' style={{ fontWeight: 'bold', fontSize: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                              {`${product.motor?.originalTorque}`}
                              <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                                NM
                              </Typography>
                            </Typography>
                            <ArrowDown size={35} color='#f15b2b' />
                            <Typography variant='h6' component='p' style={{ color: 'grey' }}>
                              MODIFICADO
                            </Typography>
                            <Typography variant='h6' color='primary' style={{ fontWeight: 'bold', fontSize: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                              {`${product.newTorque}`}
                              <Typography variant='h6' color='textPrimary' style={{ fontSize: '15px' }}>
                                NM
                              </Typography>
                            </Typography>
                          </List>
                        </Grid>
                      </Grid>
                      <Typography className={classes.pos} color='textSecondary'>
                        {product.description}
                      </Typography>
                    </CardContent>
                    <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography variant='h6' color='textSecondary' style={{ fontWeight: 'bold', fontSize: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {product.price ? currency(product.price) : 'Consulte valor'}
                      </Typography>
                    </CardActions>
                  </Card>)}
              </>)}
          </Grid>
        </Form>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className={classes.somos}>
        <Typography className={classes.somosTitle}>{t('perfortech.somosTitle')}</Typography>
        <Typography className={classes.somosSub}>{t('perfortech.somosSub')}</Typography>
        {/* <Link href='/contact' variant='body2' color='textSecondary'> */}
        <Link href='/' variant='body2' color='textSecondary'>
          <Button className={classes.somosButton} variant='contained' color='primary'>{t('perfortech.contato')}</Button>
        </Link>
      </Grid>

      <Grid container xs={12} sm={12} md={12} className={classes.sobreContainer}>
        <Grid item xs={6} sm={6} md={6} className={classes.sobre}>
          <Typography className={classes.sobreTitle}>{t('perfortech.sobreTitle')}</Typography>
          <Typography className={classes.sobreSub}>{t('perfortech.sobreSub')}</Typography>
          <Link href='/about' variant='body2' color='textSecondary'>
            <Button className={classes.somosButton} type='submit' variant='contained' color='primary'>{t('perfortech.sobre')}</Button>
          </Link>
          <Link href='/posts' variant='body2' color='textSecondary'>
            <Button className={classes.somosButton} type='submit' variant='contained' color='primary'>{t('perfortech.posts')}</Button>
          </Link>
        </Grid>
        <Grid container xs={6} sm={6} md={6} className={classes.sobre2}>
          <Grid item xs={12} sm={12} md={12} className={classes.sobreImage} />
        </Grid>
      </Grid>

      <Grid container className={classes.sobreContainerWhite}>
        <Grid item xs={12} sm={12} md={12} className={classes.select}>
          <Typography variant='h4' className={classes.selectTitle}>{t('prodigy.behinde')}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{ padding: '0% 5% 0% 5%' }}>
          {loading
            ? <Divider />
            : <iframe width='100%' height='420' src={configuration.behind ? configuration.behind : 'https://www.youtube.com/embed/bNltm3cGupc'} title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />}
          {/* i18n.language */}
          <Typography variant='body1' className={classes.selectTitle}>{configuration.behindBody}</Typography>

          {/* behindBody */}
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} style={{ padding: '1% 5% 5% 5%' }}>
          <Typography variant='h6' style={{
            color: 'black',
            fontWeight: '800',
            textAlign: 'left',
            margin: 0,
          }} >DYNO DEVELOPMENT</Typography>
          <Typography variant='h6' style={{
            color: 'black',
            fontWeight: '800',
            textAlign: 'left',
            margin: 0,
          }}>FOR KTM RC 390</Typography>
          <Typography variant='subtitle1'>Lorem ipsum dolor sit amet, consec</Typography>
        </Grid> */}
      </Grid>

      <Grid container xs={12} sm={12} md={12} className={classes.footer}>
        <Grid item xs={6} sm={6} md={6} className={classes.footerInfo}>
          <Typography className={classes.footerInfoFontWhite}>{t('home.follow')}</Typography>

          {configuration.facebook &&
            <Link href={'//' + configuration.facebook} target='_blank' rel='noopener noreferrer' variant='body2' color='textSecondary'>
              <IconButton edge='start' className={classes.footerIcon}>
                <Facebook size={30} />
              </IconButton>
            </Link>}
          {configuration.instagram &&
            <Link href={'//' + configuration.instagram} target='_blank' rel='noopener noreferrer' variant='body2' color='textSecondary'>
              <IconButton edge='start' className={classes.footerIcon}>
                <Instagram size={30} />
              </IconButton>
            </Link>}
          {configuration.youtube &&
            <Link href={'//' + configuration.youtube} target='_blank' rel='noopener noreferrer' variant='body2' color='textSecondary'>
              <IconButton edge='start' className={classes.footerIcon}>
                <Youtube size={30} />
              </IconButton>
            </Link>}

        </Grid>
        <Grid item xs={6} sm={6} md={6} className={classes.footerInfo} />
        <Grid item xs={6} sm={6} md={6} className={classes.footerInfo}>
          <Typography className={classes.footerInfoFontBold}>PERFOTECH MOTOR SPORT. (ESCRITÓRIO)</Typography>
          <Typography className={classes.footerInfoFont}>Av.General Mello N. 2759, Jardim Tropical SALA 02, Cuiabá - MT / CEP 78065-175</Typography>
          <Typography className={classes.footerInfoFontBold}>(65) 8405-5001</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} className={classes.footerInfo}>
          {/* <Typography className={classes.footerInfoFontBold}>FLORIPA MOTOR SPORT</Typography>
          <Typography className={classes.footerInfoFont}>Av. Patricio Caldeira de Andrade N. 1180, Capoeiras, Florianópolis - SC / CEP 88085-150</Typography>
          <Typography className={classes.footerInfoFontBold}>(48) 3211-8001</Typography> */}
        </Grid>
        <div style={{ textAlign: 'center', margin: 'auto', paddingBottom: 20, paddingTop: 40 }}>
          <Typography className={classes.footerInfoFont}>© Copyright 2022 - Perfortech - Todos direitos reservados</Typography>
        </div>
      </Grid>
      {configuration.whatsapp &&
        <Container style={{ position: 'absolute' }}>
          <FloatingWhatsApp
            phoneNumber={configuration.whatsapp}
            accountName='Prodigy Performance'
            avatar='/perfortech.png'
            darkMode={false}
            statusMessage='Prodigy Performance'
            chatMessage='Olá, como podemos ajudar?'
            allowClickAway
            placeholder='Digite aqui...'
          />
        </Container>}
    </Grid>
  )
}

export default Home
