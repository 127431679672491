import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  listItem: {
    padding: '8px'
  },
  dark: {
    padding: '8px',
    backgroundColor: theme.palette.secondary.grey,
    color: 'white',
    '& svg': {
      color: 'white'
    }
  },
  shell: {
    color: 'white',
    '& svg': {
      color: 'white'
    },
    marginBottom: '4px',
    borderRadius: '4px'
  },
  nested: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    color: '#efeeee',
    '& svg': {
      color: '#efeeee'
    },
    marginBottom: '4px',
    borderRadius: '4px'
  }
}))

export default useStyles
