import { makeStyles } from '@material-ui/core'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'transparent',
    boxShadow: 0
  },

  toolBar: {
    margin: 20,
    borderRadius: 5,
    border: '3px solid #f15b2b',
    background: '#000000',
    padding: 0
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    // color: '#ffffff'
    filter: 'invert(100%)'
  },
  drawerList: {
    width: '40vw',
    padding: 20,
    background: '#272727',
    flex: '1',
    color: '#fff',
    textAlign: 'center'
  },
  drawerPaper: {
    background: '#fff',
    border: 0,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7)
    }
  },
  logo: {
    position: 'relative',
    marginBottom: theme.spacing(1),
    height: 60,
    top: 7,
    left: 7
  }
}))

export default useStyles
