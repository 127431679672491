const firebaseConfig = {
  apiKey: 'AIzaSyCHBUIqHLAD-A-PQxd6tPsaMO8eKqsSwow',
  authDomain: 'perfortech-85e1d.firebaseapp.com',
  projectId: 'perfortech-85e1d',
  storageBucket: 'perfortech-85e1d.appspot.com',
  messagingSenderId: '659363527666',
  appId: '1:659363527666:web:2806a439ef53a38670c96b'
}

export default {
  development: {
    api: 'http://localhost:5555/v1/',
    blob: 'http://localhost:5555/v1/static/storage/',
    publicBlob: 'http://localhost:5555/v1/static/public/',
    socket: 'http://localhost:5554',
    token: 'bCmFlczI1Ni1jdH3BlbnZXktdjEAB0AAAAGAAAABCuNzaC1rqwDgkfAAAAIAAAAGYmNyeX'
  },
  remote: {
    api: 'https://api.development.agiontecnologia.com.br/v1/',
    blob: 'https://api.development.agiontecnologia.com.br/v1/static/storage/',
    publicBlob: 'https://api.development.agiontecnologia.com.br/v1/static/public/',
    socket: 'https://socket.development.agiontecnologia.com.br',
    token: 'bCmFlczI1Ni1jdH3BlbnZXktdjEAB0AAAAGAAAABCuNzaC1rqwDgkfAAAAIAAAAGYmNyeX'
  },
  production: {
    api: 'https://api.perfortechmotorsport.com/v1/',
    blob: 'https://api.perfortechmotorsport.com/v1/static/storage/',
    publicBlob: 'https://api.perfortechmotorsport.com/v1/static/public/',
    socket: 'https://socket.perfortechmotorsport.com',
    token: 'bCmFlczI1Ni1jdH3BlbnZXktdjEAB0AAAAGAAAABCuNzaC1rqwDgkfAAAAIAAAAGYmNyeX'
  }
}

export { firebaseConfig }
