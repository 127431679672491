import React from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
// import clsx from 'clsx'

import useStyles from './styles'

const SignInput = ({ visible = true, name, to, Icon, primary, nested }) => {
  const classes = useStyles()

  return (
    // <div className={nested && classes.nested} style={{ background: primary ? '#696969' : '#f15b2b', color: 'white', marginBottom: '4px', borderRadius: '4px' }}>
    <div className={nested ? classes.nested : (primary ? classes.primary : classes.shell)}>
      {visible &&
        <ListItem
          button
          // TODO: Fix the active class
          activeClassName={classes.active}
          component={RouterLink} to={to}
          className={classes.listItem}
          selected={window.location.pathname === to}
        // className={clsx(classes.listItem, primary && classes.primary)}
        >
          <ListItemIcon><Icon /></ListItemIcon>
          <ListItemText primary={name} />
        </ListItem>}
    </div>
  )
}

export default SignInput
