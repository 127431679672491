import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  active: {
    background: 'pink',
    color: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  listItem: {
    padding: '8px',
    '&.Mui-selected': {
      color: 'black',
      '& svg': {
        color: 'black'
      },
      backgroundColor: '#fff',
      '& .MuiListItemIcon-root': { color: 'black' },
      '&:hover': {
        color: 'black',
        '& svg': {
          color: 'black'
        },
        backgroundColor: '#f15b2b'
      }
    },
    '&:hover': {
      color: 'black',
      '& svg': {
        color: 'black'
      },
      backgroundColor: '#f15b2b',
      '& .MuiListItemIcon-root': {
        color: 'black',
        '& svg': {
          color: 'black'
        }
      }
    }
  },
  primary: {
    color: '#f15b2b',
    '& svg': {
      color: '#f15b2b'
    },
    marginBottom: '4px',
    borderRadius: '4px'
  },
  shell: {
    color: 'white',
    '& svg': {
      color: 'white'
    },
    marginBottom: '4px',
    borderRadius: '4px'
  },
  nested: {
    marginLeft: theme.spacing(3),
    color: '#efeeee',
    '& svg': {
      color: '#efeeee'
    },
    marginBottom: '4px',
    borderRadius: '4px'
  }
}))

export default useStyles
