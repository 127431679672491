import React, { useState, useCallback, useEffect } from 'react'
import { Button, Box, Drawer, List, Link, ListItem, Hidden, IconButton, AppBar, Toolbar, Divider } from '@material-ui/core'
import { LogIn, Menu } from 'react-feather'
import { useTranslation } from 'react-i18next'

import LanguageSelect from '../LanguageSelect'
import api from '../../services/api'
import useStyles from './style'

const OpenNavigation = () => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [store, setStore] = useState(false)

  const { t } = useTranslation()

  const fetchStore = useCallback(async () => {
    const response = await api.get('/configuration/store')
    setStore(response.data)
  }, [])

  useEffect(() => {
    fetchStore()
  }, [])

  return (
    <>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolBar} variant='dense'>
          <a href='/' style={{ color: 'white' }}>
            <img src='/perfortech-branco.png' className={classes.logo} />
          </a>
          <Box flexGrow={1} />
          <Hidden mdUp>
            <IconButton edge='start' onClick={() => setOpen(!open)} className={classes.menuButton} aria-label='open drawer'>
              <Menu />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            {store?.store &&
              <a style={{ textDecoration: 'none' }} href={store?.store} target='_blank' rel='noreferrer'>
                <Button size='medium' color='primary' style={{ marginRight: 10, paddingRight: 20, paddingLeft: 20 }}>
                  {t('menu.store')}
                </Button>
              </a>}
            <Button href='/about' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.sobre')}
            </Button>
            <Button href='/stage-services' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.stages')}
            </Button>
            <Button href='/contact' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.contato')}
            </Button>
            <Button href='/products' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.performance')}
            </Button>
            <Button href='/news' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.posts')}
            </Button>
            <Button href='/dealers' color='secondary' style={{ marginRight: 5, paddingRight: 5, paddingLeft: 5 }}>
              {t('menu.dealers')}
            </Button>
            <LanguageSelect />
            <Link href='/login' variant='body2' color='textSecondary'>
              <IconButton edge='start' className={classes.menuButton} color='inherit'>
                <LogIn />
              </IconButton>
            </Link>
          </Hidden>
        </Toolbar>
      </AppBar>
      {/* <Drawer classes={{ paper: classes.drawerPaper }} open={open} onClose={() => setOpen(!open)}> */}
      {/* <Drawer anchor='left' open={open} onClose={() => setOpen(!open)}>
        <List className={classes.drawerList}>
          <DrawerButtons to='/' title='HOME' />
          <DrawerButtons to='/products' title='PRODUCTS' />
          <DrawerButtons to='/dealers' title='DEALERS' />
          <DrawerButtons to='/about' title='ABOUT US' />
          <DrawerButtons to='/contact' title='CONTATO' />
          <LoginButtons />
          <div style={{ position: 'absolute', top: 10, left: 0 }}>
            <LanguageSelect />
          </div>
        </List>
      </Drawer> */}
      <Drawer anchor='left' open={open} onClose={() => setOpen(!open)}>
        <List className={classes.drawerList}>
          <img src='/perfortech-branco.png' className={classes.sideLogo} />
          {store?.store &&
            <>
              <ListItem button>
                <Button href={store?.store} target='_blank' fullWidth variant='contained' color='secondary'>
                  {t('menu.store')}
                </Button>
              </ListItem>
            </>}
          <ListItem button>
            <Button href='/about' fullWidth variant='text' color='secondary'>
              {t('menu.sobre')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/stage-services' fullWidth variant='text' color='secondary'>
              {t('menu.stages')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/contact' fullWidth variant='text' color='secondary'>
              {t('menu.contato')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/products' fullWidth variant='text' color='secondary'>
              {t('menu.performance')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/news' fullWidth variant='text' color='secondary'>
              {t('menu.posts')}
            </Button>
          </ListItem>
          <Divider />
          <ListItem button>
            <Button href='/dealers' fullWidth variant='text' color='secondary'>
              {t('menu.dealers')}
            </Button>
          </ListItem>
          <Divider />
          <LanguageSelect />
          <Divider />
          <Link href='/login' variant='body2' color='textSecondary'>
            <IconButton edge='start' className={classes.menuButton} color='inherit'>
              <LogIn />
            </IconButton>
          </Link>
        </List>
      </Drawer>
    </ >
  )
}

export default OpenNavigation
