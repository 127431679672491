import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import config from '../../config'

const ProductHeader = ({ _id, name }) => {
  return (
    <Grid item xs={12} sm={12} md={12} style={{ background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/0.jpg') url(${config[process.env.NODE_ENV].publicBlob}/${_id}.jpg)`, justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundPosition: 'center', backgroundSize: 'cover', overflow: 'hidden', height: '45%' }}>
      <Typography variant='h2' color='primary' style={{ marginTop: 100, alignContent: 'center', textAlign: 'center', justifyContent: 'center', fontWeight: 'normal' }}>
        {name}
      </Typography>
    </Grid>
  )
}

export default ProductHeader
