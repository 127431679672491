import React, { useCallback, useState, useEffect } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, Divider, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { Plus } from 'react-feather'

import Body from '../../../components/Body'
import api from '../../../services/api'

import useStyles from './style'

const LogsIndex = () => {
  const navigate = useNavigate()
  const classes = useStyles()

  const [logs, setLogs] = useState([])
  const [loading, setLoading] = useState([])

  const fetchLogs = useCallback(async () => {
    const response = await api.get('logs')
    setLogs(response.data)
    setLoading(false)
  }, [])

  const handlerNew = useCallback(() => {
    navigate('/log/novo')
  }, [])

  const handlerView = useCallback(({ log }) => {
    navigate(`/log/${log._id}`)
  }, [])

  useEffect(async () => {
    await fetchLogs()
  }, [])

  return (
    <Body title='Logs'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                Logs
              </Typography>
              <Tooltip title='Novo post' arrow placement='right'>
                <IconButton size='small' aria-label='novo-post' className={classes.plus} onClick={handlerNew}><Plus /></IconButton>
              </Tooltip>
            </Grid>
            {loading && <LinearProgress />}
            {logs.length > 0
              ? (
                <List component='nav' aria-label='logs'>
                  {logs.map((log, index) => (
                    <div key={index}>
                      <ListItem button onClick={() => handlerView({ log })}>
                        <ListItemText primary={`${log.name.toUpperCase()} - ${log.fuel?.name?.toUpperCase()} / ${log.files?.length} arquivo${log.files?.length > 1 ? 's' : ''}`} />
                      </ListItem>
                      <Divider className={classes.divider} />
                    </div>
                  ))}
                </List>)
              : <Typography variant='body1' component='p'>Nenhum log encontrado</Typography>}
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default LogsIndex
