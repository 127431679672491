import React, { useState, useCallback } from 'react'
import { Drawer, AppBar, Toolbar, Typography, Box, Collapse, IconButton, List, Link, Menu, MenuItem, ListItemIcon, Hidden, Tooltip } from '@material-ui/core'
import { BarChart2, Layout, User, Users, Plus, AlignLeft, DollarSign, FilePlus, Home, Box as BoxIcon, Monitor, LogOut, Key, MapPin, Database, Menu as MenuIcon, MoreVertical, X, ChevronLeft, Download } from 'react-feather'
import { NavLink as RouterLink, useNavigate, Outlet } from 'react-router-dom'
import clsx from 'clsx'

import LanguageSelect from '../components/LanguageSelect'
import CollapseSideListItem from '../components/CollapseSideListItem'
import SideListItem from '../components/SideListItem'
// import { hourMessage } from '../services/atom'

import { useAuth } from '../hooks'

import useStyles from './styles'

const Navigation = ({ sideBar = true, children }) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { signOut, user } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)

  const openMenu = Boolean(anchorEl)

  const handleOpenMenu = (event) => { setAnchorEl(event.currentTarget) }
  const handleCloseMenu = () => { setAnchorEl(null) }
  const signOutFunction = () => { signOut() }

  const [open, setOpen] = useState(() => {
    const open = window.localStorage.getItem('#perfortech:sidebar')
    return open === 'true'
  })

  const [portal, setPortal] = useState(() => {
    const portal = window.localStorage.getItem('#perfortech:portal')
    return portal === 'true'
  })

  const [performance, setPerformance] = useState(() => {
    const performance = window.localStorage.getItem('#perfortech:performance')
    return performance === 'true'
  })

  const handleDrawer = () => {
    window.localStorage.setItem('#perfortech:sidebar', !open)
    if (portal) { handlePortal() }
    if (performance) { handlePerformance() }
    setOpen(!open)
  }

  const handlePortal = () => {
    if (!open) { setOpen(!open) }
    window.localStorage.setItem('#perfortech:portal', !portal)
    setPortal(!portal)
  }

  const handlePerformance = () => {
    if (!open) { setOpen(!open) }
    window.localStorage.setItem('#perfortech:performance', !performance)
    setPerformance(!performance)
  }

  const SideList = useCallback(() => (
    <List>
      <SideListItem name='Inicio' to='/inicio' Icon={Home} />
      {user.super && <><SideListItem name='Financeiro' to='/financeiro' Icon={DollarSign} /></>}
      {user.dealer && <><SideListItem name='Financeiro' to='/financeiro' Icon={DollarSign} /></>}
      {user.admin &&
        <>
          <SideListItem name='Dealers' to='/admin/dealers' Icon={MapPin} />
          <SideListItem name='Stages' to='/stages' Icon={BoxIcon} />
          <CollapseSideListItem name='Performance' Icon={BarChart2} onClick={handlePerformance} up={performance} />
          <Collapse in={performance} timeout='auto' unmountOnExit>
            <List disablePadding>
              <SideListItem name='Adicionais' to='/adicionais' Icon={BarChart2} nested />
              <SideListItem name='Fabricantes' to='/fabricantes' Icon={BarChart2} nested />
              <SideListItem name='Modelos' to='/modelos' Icon={BarChart2} nested />
              <SideListItem name='Anos' to='/anos' Icon={BarChart2} nested />
              <SideListItem name='Versão/Motores' to='/motores' Icon={BarChart2} nested />
              <SideListItem name='Tipo performance' to='/stage/tipos' Icon={BarChart2} nested />
              <SideListItem name='Combustíveis' to='/combustiveis' Icon={BarChart2} nested />
              <SideListItem name='Equipamentos' to='/equipamentos' Icon={BarChart2} nested />
              {/* NOVO: Lista de chats decrecente, chats abertos, alertar mensagens */}
            </List>
          </Collapse>
          <CollapseSideListItem name='Portal' Icon={Layout} onClick={handlePortal} up={portal} />
          <Collapse in={portal} timeout='auto' unmountOnExit>
            <List disablePadding>
              <SideListItem name='Novidades' to='/novidades' Icon={AlignLeft} nested />
              <SideListItem name='Representantes' to='/representantes' Icon={Users} nested />
              <SideListItem name='Configurações' to='/configuracoes' Icon={Monitor} nested />
            </List>
          </Collapse>
          <SideListItem name='Administradores' to='/administradores' Icon={User} />
        </>}
      <SideListItem name='Downloads' to='/downloads' Icon={Download} />
      <SideListItem name='Pedidos' to='/pedidos' Icon={FilePlus} />
      <SideListItem name='Novo pedido' to='/pedido/novo' Icon={Plus} primary />
    </List>
  ), [user, portal, performance])

  return (
    <>
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <div className={classes.root}>
          <AppBar elevation={0} className={classes.appBarSm}>
            <Toolbar className={classes.toolbar} variant='dense'>
              <IconButton edge='start' color='inherit' aria-label='abrir sidebar' onClick={handleDrawer} className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
              <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
                <Link noWrap color='inherit' component={RouterLink} to='/inicio'>
                  <Tooltip title='Home' placement='bottom'>
                    <img src='/perfortech.png' className={classes.logoTiny} />
                  </Tooltip>
                </Link>
              </Typography>
              <IconButton color='inherit' onClick={() => navigate(-1)}>
                <ChevronLeft />
              </IconButton>
              <Link noWrap color='inherit' component={RouterLink} to='/'>
                <IconButton color='inherit'>
                  <Layout />
                </IconButton>
              </Link>
              <IconButton color='inherit' aria-label='Sair' onClick={handleOpenMenu} style={{ marginRight: 10 }}>
                <MoreVertical size={20} />
              </IconButton>
              <Menu anchorEl={anchorEl} keepMounted open={openMenu} onClose={handleCloseMenu}>
                <MenuItem disabled>
                  <ListItemIcon>
                    <User size={20} />
                  </ListItemIcon>
                  <Typography color='inherit' noWrap>
                    {user?.name}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate('/trocar/senha')}>
                  <ListItemIcon>
                    <Key size={20} />
                  </ListItemIcon>
                  Trocar senha
                </MenuItem>
                <MenuItem onClick={signOutFunction}>
                  <ListItemIcon>
                    <LogOut size={20} />
                  </ListItemIcon>
                  Sair
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
          <Drawer classes={{ paper: classes.drawerPaper }} open={open} onClose={handleDrawer}>
            <div className={classes.toolbarIcon}>
              <IconButton edge='start' color='primary' aria-label='fechar sidebar' onClick={handleDrawer}>
                <X />
              </IconButton>
            </div>
            <div className={classes.paper}>
              <SideList />
            </div>
            <Box flexGrow={1} />
            {user.super && <List className={classes.paper}><SideListItem name='Logs' to='/logs' Icon={Database} /></List>}
            <LanguageSelect style={{ alignSelf: 'center' }} />
            {/* <div className={classes.toolbarIconCenter}>
              <IconButton aria-label='dark-mode' onClick={handleDark}>
                {dark ? <Sun /> : <Moon />}
              </IconButton>
            </div> */}
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Outlet />
          </main>
        </div>
      </Hidden>
      <Hidden only={['xs']}>
        <div className={classes.root}>
          <AppBar elevation={0} className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar} variant='dense'>
              <IconButton color='inherit' onClick={() => navigate(-1)}>
                <ChevronLeft />
              </IconButton>
              <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
                <Link noWrap color='inherit' component={RouterLink} to='/inicio'>
                  <Tooltip title='Home' placement='bottom'>
                    <img src='/perfortech.png' className={classes.logoTiny} />
                  </Tooltip>
                </Link>
              </Typography>
              <div />
              {/* <Typography align='center' color='textSecondary'>{hourMessage()} {user?.name.split(' ')[0]}</Typography> */}
              <Box flexGrow={1} />
              <Link noWrap color='inherit' component={RouterLink} to='/'>
                <IconButton color='primary'>
                  <Layout />
                </IconButton>
              </Link>
              {sideBar &&
                <>
                  {user.dealer?.name && <Typography color='textPrimary' noWrap>{user.dealer?.name}</Typography>}
                  <IconButton color='primary' aria-label='user'><User size={20} /></IconButton>
                  <Typography color='textPrimary' noWrap>{user?.name}</Typography>
                </>}
              <IconButton color='inherit' aria-label='Sair' onClick={handleOpenMenu}>
                <MoreVertical size={20} />
              </IconButton>
              <Menu anchorEl={anchorEl} keepMounted open={openMenu} onClose={handleCloseMenu}>
                <MenuItem disabled>
                  <ListItemIcon>
                    <User size={20} />
                  </ListItemIcon>
                  <Typography color='inherit' noWrap>
                    {user?.name}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate('/trocar/senha')}>
                  <ListItemIcon>
                    <Key size={20} />
                  </ListItemIcon>
                  Trocar senha
                </MenuItem>
                <MenuItem onClick={signOutFunction}>
                  <ListItemIcon>
                    <LogOut size={20} />
                  </ListItemIcon>
                  Sair
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
          <Drawer variant={sideBar && 'permanent'} classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }} open={open}>
            <div className={classes.toolbarIcon}>
              <IconButton color='primary' aria-label='fechar sidebar' onClick={handleDrawer}>
                {open ? <X /> : <MenuIcon />}
              </IconButton>
            </div>
            <div className={classes.paper}>
              {/* <Container className={classes.logo} disableGutters>
                <Link noWrap color='inherit' component={RouterLink} to='/inicio'>
                  <Tooltip title='Home' placement='bottom'>
                    <img src='/perfortech.png' className={classes.logo} />
                  </Tooltip>
                </Link>
              </Container> */}
              <SideList />
            </div>
            <Box flexGrow={1} />
            {user.super && <List className={classes.paper}><SideListItem name='Logs' to='/logs' Icon={Database} /></List>}
            <div className={classes.toolbarIconCenter}>
              <LanguageSelect />
            </div>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            {children}
          </main>
        </div>
      </Hidden>
    </>
  )
}

export default Navigation
