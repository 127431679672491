import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Grid, Paper, List, ListItem, ListItemText, IconButton, Tooltip as MUITooltip, Typography } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'

import { Edit2, Trash2 } from 'react-feather'
import Chart from 'react-apexcharts'

import api, { blobAPI } from '../../../services/api'
import Body from '../../../components/Body'

import useStyles from './style'

const logView = () => {
  const classes = useStyles()
  const { _id } = useParams()
  const navigate = useNavigate()
  const chartRef = useRef()

  const [log, setLog] = useState([])
  const [datas, setDatas] = useState([])
  const [series, setSeries] = useState([])
  const [loading, setLoading] = useState(true)

  const creatBlob = async (fileName) => {
    const responseFile = await blobAPI.get(fileName)
    const file = new Blob([responseFile.data], { type: responseFile.data.type })
    const blobURL = URL.createObjectURL(file)

    return blobURL
  }

  const fetchLog = useCallback(async () => {
    const response = await api.get(`/log/${_id}`)
    setLog(response.data)
    if (response.data?.files?.length > 0) {
      const thisDatas = []
      const thisSeries = {}
      for (let i = 0; i < response.data.files.length; i++) {
        if (response.data.files[i].name.split('.').pop() === 'csv') {
          const name = response.data.files[i].name
          const newFile = await creatBlob(response.data.files[i].name)
          const responseFile = await fetch(newFile)
          const data = await responseFile.arrayBuffer()
          const decoder = new TextDecoder('utf-8')
          const dataString = decoder.decode(data)
          const csv = dataString
          const datasets = []
          const rows = csv.split('\n')
          const labels = rows[0].split(',')
          thisSeries[name] = []
          for (let i = 0; i < labels.length; i++) {
            const label = labels[i]
            const data = []

            for (let j = 3; j < rows.length; j++) {
              if (parseFloat(rows[j].split(',')[i])) {
                data.push(parseFloat(rows[j].split(',')[i]))
              }
            }

            thisSeries[name].push({ name: label, data })
          }
          thisDatas.push({
            labels,
            datasets
          })
          setDatas([...thisDatas])
          setSeries(thisSeries)
        }
      }
      setLoading(false)
    }
  }, [_id, datas])

  const handlerEdit = useCallback(() => {
    navigate(`/log/${_id}/update`)
  }, [_id])

  const handlerDelete = useCallback(async ({ index }) => {
    const confirm = window.confirm('Deseja realmente excluir este log?')
    if (!confirm) return
    await api.delete(`/log/${_id}/file/${index}`)
    await fetchLog()
  }, [_id])

  useEffect(async () => {
    await fetchLog()
  }, [])

  const options = {
    chart: {
      height: 500,
      type: 'line',
      zoom: { enabled: false },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 10
        },
        dynamicAnimation: {
          enabled: false,
          speed: 10
        }
      }
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: false,
      showForZeroSeries: false,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      // fontSize: '14px',
      // fontFamily: 'Helvetica, Arial',
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false
      }
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'straight' },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }
    },
    yaxis: {
      title: {
        text: 'RPM'
      },
      labels: { formatter: function (val) { return val.toFixed(0) } }
    },
    // yaxis: {
    //   title: {
    //     text: 'Temperature'
    //   },
    //   min: 0
    // },
    // disable x axis labels
    xaxis: {
      labels: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    }
  }

  return (
    <Body title='Log'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant='outlined' className={classes.paper} elevation={0}>
            <Grid container direction='row'>
              <Typography variant='h5' component='h2'>
                {log.name}
              </Typography>
              <MUITooltip title='EDITAR' arrow placement='right'>
                <IconButton size='small' aria-label='editar-log' className={classes.plus} onClick={() => handlerEdit({ log })}><Edit2 /></IconButton>
              </MUITooltip>
            </Grid>
            <List component='nav' aria-label='log'>
              <ListItem>
                <ListItemText primary='NOTAS' secondary={log.notes} style={{ whiteSpace: 'pre' }} />
              </ListItem>
              {log.files && log.files.length > 0 && !loading && log.files.map((file, index) => (
                <Paper key={index} className={classes.chart} elevation={3}>
                  <Typography variant='h6' component='h2'>
                    {file.originalName}
                    <IconButton size='small' aria-label='editar-log' className={classes.trash} onClick={() => handlerDelete({ index })}><Trash2 size={20} /></IconButton>
                  </Typography>
                  <Chart ref={chartRef} type='line' options={options} series={series[file.name]} height={500} />
                </Paper>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Body>
  )
}

export default logView
